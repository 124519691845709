import React, {useEffect, useState} from 'react';
import { navigate } from "@reach/router"
import Layout from "../components/shared/layout";
const Redirect301Template = ({pageContext }) => {
  const [redirectUrl, setRedirectUrl] = useState(pageContext.redirectUrl);
  useEffect(() => {
    console.log(redirectUrl);
    navigate(redirectUrl);
  }, [redirectUrl]);
  return (
    <Layout>
      <h1>301 Redirect</h1>
    </Layout>
  );
};
export default Redirect301Template;